export const routes = [
    {
        path: '/payment_types',
        name: 'payment_types.browse',
        component: () => import(/* webpackChunkName: "PaymentTypesBrowse" */ '@/views/app/PaymentTypesBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/payment_types/create',
        name: 'payment_types.create',
        component: () => import(/* webpackChunkName: "PaymentTypesActions" */ '@/views/app/PaymentTypesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/payment_types/:id/edit',
        name: 'payment_types.edit',
        component: () => import(/* webpackChunkName: "PaymentTypesActions" */ '@/views/app/PaymentTypesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/payment_types/:id/delete',
        name: 'payment_types.delete',
        component: () => import(/* webpackChunkName: "PaymentTypesActions" */ '@/views/app/PaymentTypesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]